<template>
  <div class="wrapper">
    <!-- <div>substations: {{substations}}</div> -->
    <side-bar>
      <template slot="links">
        <!-- <sidebar-link to="/dashboard" :name="$t('sidebar.dashboard')" icon="tim-icons icon-chart-pie-36"/> -->
        <!-- <sidebar-link to="/dashboard" :name="$t('Test Canvas')" icon="tim-icons icon-chart-pie-36"/> -->
        <!-- <sidebar-link to="/amk_industrial_park_ii" :name="$t('AMK Industrial Park II')" icon="tim-icons icon-chart-pie-36"/> -->
        <!-- <sidebar-link to="/amk_industrial_park_ii" :name="$t('Test Substation')" icon="tim-icons icon-chart-pie-36"/> -->
        <!-- <sidebar-link to="/icons" :name="$t('sidebar.icons')" icon="tim-icons icon-atom"/>
        <sidebar-link to="/maps" :name="$t('sidebar.maps')" icon="tim-icons icon-pin"/>
        <sidebar-link to="/notifications" :name="$t('sidebar.notifications')" icon="tim-icons icon-bell-55"/>
        <sidebar-link to="/profile" :name="$t('sidebar.userProfile')" icon="tim-icons icon-single-02"/>
        <sidebar-link to="/table-list" :name="$t('sidebar.tableList')" icon="tim-icons icon-puzzle-10"/>
        <sidebar-link to="/typography" :name="$t('sidebar.typography')" icon="tim-icons icon-align-center"/>
        <sidebar-link to="/dashboard?enableRTL=true" :name="$t('sidebar.rtlSupport')" icon="tim-icons icon-world"/> -->
        <sidebar-link :to="`/substationDashboard/${item.id}`" v-for="item in substations" :key="item.id" :name="item.name" icon="tim-icons icon-chart-pie-36"/>
        <!-- <sidebar-link :to="`/substationDashboardNew/${item.id}`" v-for="item in substations" :key="item.id" :name="item.name + ' NEW'" icon="tim-icons icon-chart-pie-36"/> -->
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar">

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";

// import axios from "axios";
import SidebarLink from '../../components/SidebarPlugin/SidebarLink.vue';

import api from "Api";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    SidebarLink
  },
  data(){
    return {
      substations: [],
    };
  },
  created(){
    this.getSubstations();
  },
  methods: {
    getSubstations() {
      // axios.get("http://localhost:8000/substation/substations/").then((response) => {
      //   this.substations = response.data;
      // });
      api
        .get("substation/substations/" )
        .then((response) => {
          this.substations = response.data;
        })
        .catch((error) => {});
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};
</script>
